import './App.css';
import React from 'react';

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.onStoreFilterChange = this.onStoreFilterChange.bind(this);
    this.onGenreFilterChange = this.onGenreFilterChange.bind(this);
  }

  onStoreFilterChange(e) {
    this.props.onStoreFilterChange(e);
  }

  onGenreFilterChange(e) {
    this.props.onGenreFilterChange(e);
  }

  render() {
    let storeFilterCheckboxes = [];
    for (let store in this.props.storeFilter) {
      storeFilterCheckboxes.push(
        <div key={store}>
          <label>{store}: </label>
          <input 
            type="checkbox"
            name="storeFilter"
            value={store}
            checked={this.props.storeFilter[store]}
            onChange={this.props.onFilterChange}
          />
        </div>
      );
    }
    let genreFilterCheckboxes = [];
    for (let genre in this.props.genreFilter) {
      genreFilterCheckboxes.push(
        <div key={genre}>
          <label>{genre}: </label>
          <input
            type="checkbox"
            name="genreFilter"
            value={genre}
            checked={this.props.genreFilter[genre]}
            onChange={this.props.onFilterChange}
          />
        </div>
      );
    } 
    return (
      <div className="Filters">
        <div className="StoreFilter">
          <label>
            Store Filer
          </label>
          {storeFilterCheckboxes}
        </div>
        <div className="GenreFilter">
          <label>Genre Filter</label>
          {genreFilterCheckboxes}
        </div>
      </div>
    )
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onSearchChange(e.target.value);
  }

  render() {
    return (
      <div className="SearchBar">
        <input type="text" value={this.props.searchFilter} onChange={this.handleChange} />
      </div>
    );
  }
}

class GameGrid extends React.Component {
  render() {
    const gridElements = this.props.games.map((game) => 
      <div className="GridElement" key={game.Id}>
        <div className="GameThumbnail">
          <img src={'/thumbnails/'+game.Thumbnail} alt={game.Thumbnail}/>
        </div>
        <div className="GameInfo">
          <label className="GameName">{game.Name}</label>
        </div>
      </div>
    );

    return (
      <div className="GameGrid">
        {gridElements}
      </div>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);

    this.state = {
      storeFilter: {
        Epic: false,
        Steam: false,
        Gog: false
      },
      genreFilter: {
        Action: false,
        Adventure: false,
        FPS: false,
        MMO: false,
        Racing: false,
        RPG: false,
        Simulation: false,
        Sports: false
      },
      searchFilter: '',
      gridGames: this.props.games
    };
  }

  onSearchChange(value) {
    this.setState({searchFilter: value});
    console.log('Searched for ', value);

    let newGameGridState = this.props.games.slice(0, this.props.games.length);
    if (value) {
      newGameGridState = newGameGridState.filter(game => {
        return game.Name.toLowerCase().includes(value.toLowerCase());
      });
    }
    this.setState({gridGames: newGameGridState});
  }
  
  onFilterChange(value) {
    const filterName = value.target.name;
    const filterLabel = value.target.value;
    const checked = value.target.checked;
    
    if (filterName === 'storeFilter') {
      let newState = this.state.storeFilter;
      newState[filterLabel] = checked;
      this.setState({storeFilter: newState});
    } else if (filterName === 'genreFilter') {
      let newState = this.state.genreFilter;
      newState[filterLabel] = checked;
      this.setState({genreFilter: newState});
    }

    let filteredStore = false;
    let filteredGenre = false;
    for (let store in this.state.storeFilter) {
      if (this.state.storeFilter[store]) {
        filteredStore = true;
      }
    }
    for (let genre in this.state.genreFilter) {
      if (this.state.genreFilter[genre]) {
        filteredGenre = true;
      }
    }
    let newGameGridState = this.props.games.slice(0, this.props.games.length);
    if (filteredGenre || filteredStore) {
      if (filteredStore) {
        newGameGridState = newGameGridState.filter(game => {
          return this.state.storeFilter[game.Store];
        });
      }
      if (filteredGenre) {
        newGameGridState = newGameGridState.filter(game => {
          return this.state.genreFilter[game.Genre];
        });
      }
    }
    this.setState({gridGames: newGameGridState});
  }

  render() {
    return (
      <div className="GameApp">
        <div className="SideMenu">
          <FilterBar 
            genreFilter = {this.state.genreFilter}
            storeFilter = {this.state.storeFilter}
            onFilterChange = {this.onFilterChange}
          />
        </div>
        <div className="AppContent">
          <SearchBar 
            searchFilter = {this.state.searchFilter}
            onSearchChange={this.onSearchChange}
          />
          <GameGrid 
            games = {this.state.gridGames}
          />
        </div>
      </div>
    )
  }
}

export default App;
